<template>
  <span>
    <storyblok-component v-if="story" :blok="story.content" />
  </span>
</template>

<script setup lang="ts">
import { storyKey } from "~/injection-symbols"

definePageMeta({
  pageTransition: {
    name: "page-reverse",
  },
})

const story = (await useSbStory("index")) as Ref<StoryblokPageStory>

useTracking()

useHead({
  link: [
    {
      rel: "canonical",
      href: useSiteConfig().url,
    },
  ],
})

provide(storyKey, story.value)
</script>
